import { useEffect, useState } from "react";
import { getNextNMonths } from "./utils/time";
import "./App.css";

interface GameInfo {
  date: string;
  yes: string[];
  no: string[];
}

interface GeneralInfo {
  gameDayIndex: number;
  gameTime: string;
  location: string;
  headline: string;
  playersCount: number;
  shouldSendEmails: boolean;
}

const daysOfWeek = [0, 1, 2, 3, 4, 5, 6] as const;
const dayOfWeekDisplayName = {
  0: "poniedziałek",
  1: "wtorek",
  2: "środa",
  3: "czwartek",
  4: "piątek",
  5: "sobota",
  6: "niedziela",
};

const DEFAULT_PLAYERS_COUNT = 14;

function getDayOfWeekInPolish(dateText: string) {
  const date = new Date(dateText);
  return date.toLocaleDateString("pl-PL", { weekday: "long" });
}

function ReplyButton({
  active,
  cb,
  reply,
  user,
}: {
  active: boolean;
  cb: () => void;
  reply: string;
  user: string;
}) {
  return (
    <button
      className={`button mr1 ${active ? "active" : ""} ${reply}`}
      disabled={!user}
      onClick={() => {
        fetch("/api/post-reply", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ reply, user: user.trim() }),
        }).then(async (res) => {
          if (res.ok) {
            cb();
          } else {
            const json = await res.json();
            alert(json.error);
          }
        });
      }}
    >
      {reply}
    </button>
  );
}

function App() {
  const [gameInfo, setGameInfo] = useState<GameInfo>();
  const [organisers, setOrganisers] = useState<Record<string, string>>({});
  const [localOrganisers, setLocalOrganisers] = useState<
    Record<string, string>
  >({});
  const [generalInfo, setGeneralInfo] = useState<Partial<GeneralInfo>>();
  const [localGeneralInfo, setLocalGeneralInfo] = useState<
    Partial<GeneralInfo>
  >({});

  const [user, setUser] = useState<string>(
    localStorage.getItem("USER")
      ? JSON.parse(localStorage.getItem("USER") ?? "")
      : ""
  );

  const setUserViaPrompt = () => {
    const val = prompt("jak się nazywasz?") ?? "";
    if (!val.trim()) {
      return;
    }
    setUser(val);
    localStorage.setItem("USER", JSON.stringify(val));
  };

  useEffect(() => {
    if (!user) {
      setUserViaPrompt();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getGameInfo = () => {
    fetch("/api/get-info")
      .then((res) => res.json())
      .then((val) => {
        setGameInfo(val);
      });
  };

  useEffect(() => {
    getGameInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getOrganisers = () => {
    fetch("/api/get-organisers")
      .then((res) => res.json())
      .then((val) => {
        setLocalOrganisers(val);
        setOrganisers(val);
      });
  };

  useEffect(() => {
    getOrganisers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateOrganiser = (month: string, organiser: string) => {
    fetch("/api/post-organiser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ author: user, month, organiser }),
    }).then(async (res) => {
      if (res.ok) {
        getOrganisers();
      } else {
        const json = await res.json();
        alert(json.error);
      }
    });
  };

  const getGeneralInfo = () => {
    fetch("/api/get-general-info")
      .then((res) => res.json())
      .then((val) => val.info ?? {})
      .then((val) => {
        setLocalGeneralInfo(val);
        setGeneralInfo(val);
      });
  };

  useEffect(() => {
    getGeneralInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateGeneralInfo = (partial: Partial<GeneralInfo>) => {
    fetch("/api/post-general-info", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ author: user, partial }),
    }).then(async (res) => {
      if (res.ok) {
        window.location.reload();
      } else {
        const json = await res.json();
        alert(json.error);
      }
    });
  };

  const comingMonths = getNextNMonths(6);

  return (
    <div>
      <h1>Piłka</h1>

      {generalInfo?.headline && (
        <h2 className="headline">{generalInfo.headline}</h2>
      )}

      <div>
        <h2 className="inlineBlock mr1">{user || "jak się nazywasz?"}</h2>
        <button className="button" onClick={setUserViaPrompt}>
          edytuj
        </button>
      </div>

      <div>
        <p>
          Rozliczenia i nr konta w{" "}
          <a href="https://docs.google.com/spreadsheets/d/1O7z92TwWSBMwoz9w2MLyom8j6j1NPtiuoaiK6U-4tD4">
            arkuszu google'a
          </a>
        </p>
        {generalInfo && (
          <p className="generalInfo">
            <label className="mr1">
              dzień do gry:
              <select
                className="ml05 mr05"
                onChange={(event) => {
                  setLocalGeneralInfo({
                    ...localGeneralInfo,
                    gameDayIndex: Number(event?.currentTarget.value ?? "0"),
                  });
                }}
              >
                {daysOfWeek.map((dayIndex) => (
                  <option
                    value={dayIndex}
                    selected={dayIndex === generalInfo.gameDayIndex}
                  >
                    {dayOfWeekDisplayName[dayIndex]}
                  </option>
                ))}
              </select>
              <small
                title="determinuje dni, kiedy są wysyłane mejle"
                onClick={() => {
                  alert("determinuje dni, kiedy są wysyłane mejle");
                }}
              >
                (?)
              </small>
            </label>
            <label>
              godzina:
              <input
                className="mr1 ml05"
                type="text"
                onChange={(event) => {
                  setLocalGeneralInfo({
                    ...localGeneralInfo,
                    gameTime: event?.currentTarget.value ?? "",
                  });
                }}
                size={20}
                value={localGeneralInfo.gameTime}
              />
            </label>
            <label>
              boisko:
              <input
                className="mr1 ml05"
                type="text"
                onChange={(event) => {
                  setLocalGeneralInfo({
                    ...localGeneralInfo,
                    location: event?.currentTarget.value ?? "",
                  });
                }}
                size={30}
                value={localGeneralInfo.location}
              />
            </label>
            <label>
              duży komunikat na stronę:
              <input
                className="mr1 ml05"
                type="text"
                onChange={(event) => {
                  setLocalGeneralInfo({
                    ...localGeneralInfo,
                    headline: event?.currentTarget.value ?? "",
                  });
                }}
                size={30}
                value={localGeneralInfo.headline}
              />
            </label>
            <label className="mr1">
              wysyłać mejle?
              <select
                className="ml05 mr05"
                onChange={(event) => {
                  setLocalGeneralInfo({
                    ...localGeneralInfo,
                    shouldSendEmails: { true: true, false: false }[
                      event?.target.value ?? "true"
                    ],
                  });
                }}
              >
                {["tak", "nie"].map((yesOrNo) => (
                  <option
                    value={String({ tak: true, nie: false }[yesOrNo] ?? true)}
                    selected={
                      generalInfo.shouldSendEmails ===
                      Boolean({ tak: true, nie: false }[yesOrNo])
                    }
                  >
                    {yesOrNo}
                  </option>
                ))}
              </select>
            </label>
            <label className="mr1">
              ile osób gra?
              <select
                className="ml05 mr05"
                onChange={(event) => {
                  setLocalGeneralInfo({
                    ...localGeneralInfo,
                    playersCount: Number(event?.currentTarget.value ?? ""),
                  });
                }}
              >
                {[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map(
                  (playersCount) => (
                    <option
                      value={playersCount}
                      selected={playersCount === generalInfo.playersCount}
                    >
                      {playersCount}
                    </option>
                  )
                )}
              </select>
            </label>
            <button
              className="button buttonSmall"
              onClick={() => {
                updateGeneralInfo(localGeneralInfo);
              }}
            >
              zapisz
            </button>
          </p>
        )}
      </div>

      <hr />

      <h2>
        Będziesz {gameInfo?.date} (
        {gameInfo?.date ? getDayOfWeekInPolish(gameInfo.date) : gameInfo?.date}
        )?
      </h2>
      <ReplyButton
        cb={getGameInfo}
        reply="tak"
        user={user}
        active={gameInfo?.yes.includes(user) ?? false}
      />
      <ReplyButton
        cb={getGameInfo}
        reply="nie"
        user={user}
        active={gameInfo?.no.includes(user) ?? false}
      />
      {gameInfo?.yes && (
        <div>
          <h2 style={{ marginBottom: 0 }}>
            Zapisali się ({gameInfo?.yes.length})
          </h2>
          <p>(wg kolejności zgłoszeń)</p>
          <ul>
            {(gameInfo?.yes ?? []).map((val, i) => (
              <>
                {i ===
                  (localGeneralInfo.playersCount ?? DEFAULT_PLAYERS_COUNT) && (
                  <li>
                    <strong>Rezerwa</strong> (nie przychodzi na grę):
                  </li>
                )}
                <li
                  key={i}
                  className={`yes ${
                    i + 1 >
                    (localGeneralInfo.playersCount ?? DEFAULT_PLAYERS_COUNT)
                      ? "bench"
                      : ""
                  }`}
                >
                  {i + 1}. {val}
                </li>
              </>
            ))}
          </ul>
        </div>
      )}
      {gameInfo?.no && (
        <div>
          <h2>Dali znać, że nie będą ({gameInfo?.no.length})</h2>
          <ul>
            {(gameInfo?.no ?? []).map((val, i) => (
              <li key={i} className="no">
                {i + 1}. {val}
              </li>
            ))}
          </ul>
        </div>
      )}

      <hr />

      <h2>Organizatorzy</h2>

      <table>
        {comingMonths.map((month) => (
          <tr className="monthRow" key={month}>
            <td className="month">{month}</td>
            <td>
              <input
                className="mr1"
                type="text"
                onChange={(event) => {
                  setLocalOrganisers({
                    ...localOrganisers,
                    [month]: event?.currentTarget.value,
                  });
                }}
                size={15}
                value={localOrganisers[month]}
              />
              {localOrganisers[month] !== organisers[month] && (
                <button
                  className="button buttonSmall"
                  onClick={() => {
                    updateOrganiser(month, localOrganisers[month]);
                  }}
                >
                  zapisz
                </button>
              )}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
}

export default App;
