export function getNextNMonths(numMonths: number): string[] {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const nextNMonths: string[] = [];

  for (let i = currentMonth; i < currentMonth + numMonths; i++) {
    const month = (i % 12) + 1;
    const year = currentYear + Math.floor(i / 12);
    const monthString = month < 10 ? `0${month}` : `${month}`;
    nextNMonths.push(`${year}-${monthString}`);
  }

  return nextNMonths;
}
